<template>
  <div class="personalCenter">
    <el-row style="margin-bottom: 20px;">
      <el-col :span="24">
        <h2>会员中心</h2>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6"
              style="text-align: center;">
        <span class="avatar-img"><img :src="userInfo.memPic?userInfo.memPic:'/img/default_avatar.png'"
               alt=""></span>
      </el-col>
      <el-col :span="18">

        <h4>
          <a>{{userInfo.memName || ''}}</a>
        </h4>
        <!-- Paragraph -->
        <p><a>这家伙很懒，什么也没写！</a></p>
        <el-divider></el-divider>
        <el-row :gutter="10">
          <el-col :span="6"><strong>UID:</strong></el-col>
          <el-col :span="6">{{userInfo.memId || 'xx'}}</el-col>
          <el-col :span="6"><strong>Lv:</strong></el-col>
          <el-col :span="6">{{userInfo.memLeave}}</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="10">
          <el-col :span="6"><strong>积分:</strong></el-col>
          <el-col :span="6">{{userInfo.memScore}}</el-col>
          <el-col :span="6"><strong>余额:</strong></el-col>
          <el-col :span="6">{{userInfo.memBalance}}</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="10">
          <el-col :span="6"><strong>加入时间:</strong></el-col>
          <el-col :span="6">{{userInfo.createdAt}}</el-col>
          <el-col :span="6"><strong>上次登录:</strong></el-col>
          <el-col :span="6">{{userInfo.lastLoginTime}}</el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'personalCenter',
  data () {
    return {

    }
  },
  created () {
    console.log(this.userInfo)
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-img {
  display: inline-block;
  box-sizing: content-box;
  color: #fff;
  text-align: center;
  vertical-align: top;
  font-weight: normal;
}

.avatar-img img {
  height: 150px;
  width: 150px;
  border-radius: 150px;
  vertical-align: middle;
  border: 0;
}
</style>
